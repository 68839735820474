<template>
  <div style="height: 100%; width: 100%;">

      <el-image
        style="
          width: 100%;
          height: 100%;
          opacity: 0.2;
          border-radius: 2%;
          overflow: hidden;
          border: 1px ridge #f4e9e0;
        "
        :src="urlval"
        fit="fill"
      >
      </el-image>
  </div>
</template>

<script>
export default {
  data() {
    return {
      urlval: window.sessionStorage.getItem("project_imgurl"),
    };
  },
  created() {
    console.log(this.urlval);
  },
  methods: {},
};
</script>


<style>
</style>